<template>
  <CounterpartyCard label="Информация о контрагенте">
    <VRow>
      <VCol cols="12" md="6">
        <VRow>
          <VCol v-for="({label, value}, index) in info" cols="12" md="6" :key="index">
            <StaticField :label="label" :value="value" />
          </VCol>
        </VRow>
      </VCol>
      <VCol v-if="isReviewer || isSpectator" cols="12" md="6">
        <VRow>
          <VCol v-for="({label, value}, index) in mark.full" cols="12" :key="index">
            <StaticField :label="label" :value="value" />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </CounterpartyCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import StaticField from '@/components/general/StaticField/StaticField';
import CounterpartyCard from '@/components/pkopnr/CounterpartyCard/CounterpartyCard';
import {get} from 'lodash-es';
export default {
  name: 'CounterpartyInfo',
  components: {
    StaticField,
    CounterpartyCard,
  },
  props: {
    value: { type: Object },
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkopnr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopnr');
    },
    info() {
      return [
        {label: 'Статус', value: this.value.status.text},
        {label: 'ИНН', value: this.value.inn},
        {label: 'Название КА', value: this.value.name},
      ]
    },
    mark() {
      const value = get(this.value, 'mark.max') ?
        `${get(this.value, 'mark.full.general')} из ${get(this.value, 'mark.max')}` :
        get(this.value, 'mark.full.general')
      return {
        full: [
          {label: 'Общая квалификационная оценка', value },
        ]
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
